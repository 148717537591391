import React from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { MDXProvider, MDXProviderComponents } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import {
    Layout,
    SEO,
    Container,
    SectionSpacer,
    LocaleConfig,
    PageTitle,
    useTranslations,
} from '../core'
import { ResponsiveVideo } from '../core/ResponsiveVideo'

interface HRFAQ {
    category: string
    title: string
    order: number
    path: string
    body: string
}

interface PageLayoutProps {
    path: string
    pageContext: {
        localeConfig: LocaleConfig
        faq: HRFAQ
    }
}

// Add any components you wish to expose to MDX FAQs
const MDX_COMPONENTS: MDXProviderComponents = {
    ResponsiveVideo,
}

const HRFAQPage = ({ path, pageContext }: PageLayoutProps) => {
    const { localeConfig } = pageContext
    const { category, title, body } = pageContext.faq
    const { translate } = useTranslations(localeConfig.locale)

    return (
        <Layout
            locale={pageContext.localeConfig}
            path={path}
            hasContactSection={false}
        >
            <SEO title={title} />
            <FAQPageTitle>
                <Category>{translate(`hrfaq_category_${category}`)}</Category>
                <Title>{title}</Title>
            </FAQPageTitle>
            <Container>
                <MDXProvider components={MDX_COMPONENTS}>
                    <MDXRenderer>{body}</MDXRenderer>
                </MDXProvider>
                <BackButton onClick={() => navigate(-1)}>
                    {translate('hrfaq_back')}
                </BackButton>
            </Container>
            <SectionSpacer />
        </Layout>
    )
}

export default HRFAQPage

const FAQPageTitle = styled(PageTitle)`
    height: auto;
    padding: 24px 0;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        padding: 12px;

        h1 {
            text-align: left;
        }
    }
`

const Category = styled.div`
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    border-radius: 80px;
    font-size: 12px;
    margin-bottom: 8px;
    font-family: ${({ theme }) => theme.typography.boldFontFamily};
    background: ${({ theme }) => theme.colors.invertedBackground};
    color: ${({ theme }) => theme.colors.invertedText};

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        text-align: left;
    }
`
const Title = styled.div`
    font-size: 32px;
    line-height: 48px;
    ${({ theme }) => theme.typography_presets.bold};

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        font-size: 20px;
        line-height: 30px;
    }
`

const BackButton = styled.button`
    background-color: ${({ theme }) => theme.colors.background};
    border: 1px solid ${({ theme }) => theme.colors.invertedBackground};
    border-radius: 3px;
    width: 200px;
    height: 40px;
    margin: 32px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    white-space: nowrap;
    color: #4d4d4d;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.colors.invertedBackground};
        color: ${({ theme }) => theme.colors.invertedText};
    }
`
